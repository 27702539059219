/* Colors */
:root {
  --primary-green: #4CAF50; /* Football pitch green */
  --secondary-green: #8BC34A; /* Lighter shade of green */
  --dark-shade: #333333; /* Dark shade for gaming vibe */
  --accent-color: #FFC107; /* Gold/yellow accent, think of trophies or game coins */
  --neutral-light: #F5F5F5; /* Light background */
  --neutral-dark: #212121; /* Dark text */
}

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: var(--neutral-light);
  color: var(--neutral-dark);
}

/* Header */
header {
  background-color: var(--dark-shade);
  color: var(--accent-color);
}

/* Navbar */
.navbar-brand, .nav-link {
  color: var(--accent-color);
  transition: color 0.3s ease;
}

.navbar-brand:hover, .nav-link:hover {
  color: var(--primary-green);
}

/* Hero Section */
.hero {
  background-color: var(--primary-green);
  color: var(--neutral-light);
}

/* About Section */
.container {
  background-color: var(--neutral-light);
  color: var(--neutral-dark);
}

/* Pricing Section */
.card {
  background-color: var(--secondary-green);
  color: var(--neutral-dark);
}

.card-header {
  background-color: var(--dark-shade);
  color: var(--accent-color);
}

/* Footer */
footer {
  background-color: var(--dark-shade);
  color: var(--accent-color);
}
